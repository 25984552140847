import React from "react";
import Table from "react-bootstrap/Table";
import {httpGetWithSecurityToken, httpPostNoBodyWithSecurityToken, httpPostWithSecurityToken} from "../common/rest";
import {compareSlots, isBlank, nullToEmpty, underlineHeaderText} from "../common/tools";
import BottleSlotMenuPopUp from "./BottleSlotMenuPopUp";
import ScoreAndTastingNotesPopUp from "./ScoreAndTastingNotesPopUp";
import DeleteWineRackPopUp from "./DeleteWineRackPopUp";
import AddWineRackPopUp from "./AddWineRackPopUp";
import NavigateToRackPopUp from "./NavigateToRackPopUp";
import EditWineRackPopUp from "./EditWineRackPopUp";


/**
 * Wine Racks data structure
 *
 * wineRack {
 *     data : wineRackDto,
 *     slotArray2D : [[slotDto],[slotDto],...]
 *     wineRackAndSlotIds : [{wineRackDto.id - slotDto.id}, ...]
 * }
 *
 * wineRackData : [winerack, winerack, ...]
 *
 */
const BOTTLE_GRID_PLACEHOLDER = 'bottle_grid';
const OPEN_SLOT_PLACEHOLDER = 'open slot';

class WineRacksScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            wineRackAndSlotIds: [],
            token: props.token,
            wineRackData: [],
            bottleSuggestions: [],
            gridSuggestions: [{value: false, label: 'Every row is a shelve'}, {
                value: true,
                label: 'Bottles stacked on shelves'
            }],
            gridSelectedOption: null,
            wineRackAdded: null,

            addWineRackInputFieldsErrorMsg: null,
            bottleSlotMenuPopupTimeOut: null,

            selectedWineRackIdBottleSlotMenu: null,
            selectedSlotXPosBottleSlotMenu: null,
            selectedSlotYPosBottleSlotMenu: null,

            wineRackToDeleteId: null,
            wineRackToDeleteName: null,

            wineRackToEditId: null,
            wineRackToEdit: null,

            bottleSelectedOption: null,
            clickedBottleDescription: null
        }
        this.onChangeBottleGrid = this.onChangeBottleGrid.bind(this);
        this.addWineRack = this.addWineRack.bind(this);
        this.handleRestCallAddWineRack = this.handleRestCallAddWineRack.bind(this);
        this.clearInputFieldsWineRack = this.clearInputFieldsWineRack.bind(this);
        this.validateWineRack = this.validateWineRack.bind(this);
        this.loadWineRacks = this.loadWineRacks.bind(this);
        this.loadStockBottlesNotInWineRack = this.loadStockBottlesNotInWineRack.bind(this);
        this.determineWineRackIndexByMoveTargetId = this.determineWineRackIndexByMoveTargetId.bind(this);
        this.userClicksInsert = this.userClicksInsert.bind(this);
        this.processMouseClick = this.processMouseClick.bind(this);
        this.getBottleFromWineRackData = this.getBottleFromWineRackData.bind(this);
        this.buildWineRack = this.buildWineRack.bind(this);
        this.updateWineRackData = this.updateWineRackData.bind(this);
        this.resetElement = this.resetElement.bind(this);
        this.getWineRackById = this.getWineRackById.bind(this);

        //##### Drag and drop specific functions #####
        this.touchMove = this.touchMove.bind(this);
        this.touchStart = this.touchStart.bind(this);
        this.touchEnd = this.touchEnd.bind(this);
        this.dragStart = this.dragStart.bind(this);
        this.dragOver = this.dragOver.bind(this);
        this.drop = this.drop.bind(this);


        //####### Bottle functions ########
        this.removeBottleFromWineRack = this.removeBottleFromWineRack.bind(this);
        this.drinkBottleFromWineRack = this.drinkBottleFromWineRack.bind(this);
        this.removeBottleFromWineRackPosition = this.removeBottleFromWineRackPosition.bind(this);
        this.addBottleToBottleSuggestionsList = this.addBottleToBottleSuggestionsList.bind(this);


        //####### Delete functions ########
        this.confirmDeletion = this.confirmDeletion.bind(this);
        this.userClicksOkDeleteWineRack = this.userClicksOkDeleteWineRack.bind(this);
        this.deleteWineRack = this.deleteWineRack.bind(this);


        //####### Edit functions ##########
        this.displayWineRackEditPopup = this.displayWineRackEditPopup.bind(this);
        this.userClicksChangeNameWineRack = this.userClicksChangeNameWineRack.bind(this);
        this.userClicksCancelEditWineRack = this.userClicksCancelEditWineRack.bind(this);
        this.userClicksAddHorizontalRowOnTop = this.userClicksAddHorizontalRowOnTop.bind(this);
        this.userClicksAddHorizontalRowAtBottom = this.userClicksAddHorizontalRowAtBottom.bind(this);
        this.userClicksAddVerticalColumnAtLeft = this.userClicksAddVerticalColumnAtLeft.bind(this);
        this.userClicksAddVerticalColumnAtRight = this.userClicksAddVerticalColumnAtRight.bind(this);
        this.userClicksRemoveAllBottlesWineRack = this.userClicksRemoveAllBottlesWineRack.bind(this);
        this.userAddsOrRemovesRowsOrColumns = this.userAddsOrRemovesRowsOrColumns.bind(this);

    }

    componentDidMount() {
        underlineHeaderText('wineRacMenuId');
        document.getElementById("addWineRackFormId").style.display = "none";
        this.loadWineRacks();
        this.loadStockBottlesNotInWineRack();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }


    loadWineRacks() {
        httpGetWithSecurityToken(`/api/get-wineracks-for-user`, this.state.token)
            .then(response => {
                let wineRackArray = [];
                for (let wineRackIndex = 0; wineRackIndex < response.data.length; wineRackIndex++) {
                    let wineRack = this.buildWineRack(response.data[wineRackIndex]);
                    wineRackArray.push(wineRack);
                }

                this.setState({
                    wineRackData: wineRackArray,
                })
            }).catch(error => {
            this.setState({
                token: '',
            })
        });
    }

    loadStockBottlesNotInWineRack() {
        httpGetWithSecurityToken(`api/get-stock-bottles-not-in-winerack`, this.state.token)
            .then(response => {

                let bottleSuggestionList = response.data.map((content, idx) => {
                    let display = nullToEmpty(content.description) + ' ' + nullToEmpty(content.winery) + ' ' + nullToEmpty(content.country) + ' ' + nullToEmpty(content.vintage) + ' ' + nullToEmpty(content.price);
                    return {value: content, label: display}; //format to make the Select (bottles) component to work
                });

                this.setState({
                    bottleSuggestions: bottleSuggestionList,
                })
            }).catch(error => {
            this.setState({
                token: '',
            })
        });
    }

    determineWineRackIndexByMoveTargetId(id) {
        for (let i = 0; i < this.state.wineRackData.length; i++) {
            if (this.state.wineRackData[i].wineRackAndSlotIds.includes(id)) {
                return i;
            }
        }
        return null;
    }

    touchStart(event) {
        let moveTarget = document.elementFromPoint(  //gives back an element based on x and y coords
            event.targetTouches[0].pageX - window.pageXOffset,       //gives back the X coord of where the dragging component is now, offset needed for mobile or weird screen sizes
            event.targetTouches[0].pageY - window.pageYOffset        //gives back the Y coord of where the dragging component is now, offset needed for mobile or weird screen sizes
        );
        let wineRackAndSlotId = moveTarget.id;

        this.bottleSlotPopup(event);

        let ids = wineRackAndSlotId.split('-');

        console.log('winerack ids and slot indexes: ' + ids[0] + ' ' + ids[1] + ' ' + ids[2]);
        this.setState({
            selectedWineRackIdBottleSlotMenu: ids[0],
            selectedSlotXPosBottleSlotMenu: ids[1],
            selectedSlotYPosBottleSlotMenu: ids[2],
        })
    }

    touchMove(event) {
        clearTimeout(this.state.bottleSlotMenuPopupTimeOut); //clear the long press timer
    }

    touchEnd(event) {
        clearTimeout(this.state.bottleSlotMenuPopupTimeOut); //clear the long press timer
    }

    dragOver(event) {
        event.preventDefault();
    }

    dragStart(event) {
        let wineRackAndSlotId = event.target.id;

        console.log('id drag start: ' + wineRackAndSlotId);
        this.bottleSlotPopup(event);

        let ids = wineRackAndSlotId.split('-');

        console.log('winerack ids and slot indexes: ' + ids[0] + ' ' + ids[1] + ' ' + ids[2]);
        this.setState({
            selectedWineRackIdBottleSlotMenu: ids[0],
            selectedSlotXPosBottleSlotMenu: ids[1],
            selectedSlotYPosBottleSlotMenu: ids[2],
        })

    }

    drop(event) {
    }

    dragEnter(event) {
        event.preventDefault();
    }

    onChangeBottleGrid(selectedOption) {
        if (selectedOption != null) {
            if (selectedOption.value === true) {
                document.getElementById("nrOfBottleGrids").hidden = false;
            } else {
                document.getElementById("nrOfBottleGrids").hidden = true;
                document.getElementById("nrOfBottleGrids").value = null;
            }
            this.setState({
                gridSelectedOption: selectedOption,
            })
        } else {
            this.setState({
                gridSelectedOption: null
            })
        }
    }

    addWineRack(event) {
        event.preventDefault();
        const target = event.target;

        //TODO show illustration and explanation of what it means
        const body = {
            name: target.name.value,
            description: target.description.value,
            horizontalSize: target.horizontalSize.value,
            verticalSize: target.verticalSize.value,
            nrOfBottleGrids: target.nrOfBottleGrids.value
        };
        try {
            this.validateWineRack(target);
            this.handleRestCallAddWineRack(body, target);

            document.getElementById("addWineRackFormId").style.display = "none";

            setTimeout(() => {
                this.setState({
                    wineRackAdded: false,
                });
            }, 1000);
        } catch (err) {
            this.setState({
                addWineRackInputFieldsErrorMsg: err

            });
        }
    }


    clearInputFieldsWineRack(target) {
        target.name.value = '';
        target.description.value = '';
        target.horizontalSize.value = '';
        target.verticalSize.value = '';
        target.nrOfBottleGrids.value = '';
    }

    validateWineRack(winerack) {
        let errorMsg = '';
        if (winerack.name.value === '') {
            errorMsg = 'Please name the wine rack ';
            winerack.name.style.backgroundColor = "#F8D7DA";
            winerack.name.placeholder = 'Name - Provide a name for the wine rack ';
        } else {
            winerack.name.style.backgroundColor = "#ffffff";
            winerack.name.placeholder = 'Name';
        }

        if (isBlank(winerack.horizontalSize.value) || isNaN(winerack.horizontalSize.value) || winerack.horizontalSize.value >= 100) {
            errorMsg = errorMsg + 'Please enter a valid number < 100 ';
            winerack.horizontalSize.style.backgroundColor = "#F8D7DA";
            winerack.horizontalSize.value = '';
            winerack.horizontalSize.placeholder = '# bottles horizontal - Enter a valid number < 100 ';
        } else {
            winerack.horizontalSize.style.backgroundColor = "#ffffff";
            winerack.horizontalSize.placeholder = '# bottles horizontal';
        }

        if (isBlank(winerack.verticalSize.value) || isNaN(winerack.verticalSize.value) || winerack.verticalSize.value >= 100) {
            errorMsg = errorMsg + 'Please enter a valid number < 100 ';
            winerack.verticalSize.style.backgroundColor = "#F8D7DA";
            winerack.verticalSize.value = '';
            winerack.verticalSize.placeholder = '# bottles vertical - Enter a valid number < 100 ';
        } else {
            winerack.verticalSize.style.backgroundColor = "#ffffff";
            winerack.verticalSize.placeholder = '# bottles vertical';
        }

        //TODO this check is not really correct
        if ((!isBlank(winerack.nrOfBottleGrids.value) && isNaN(winerack.nrOfBottleGrids.value)) || winerack.nrOfBottleGrids.value >= 50) {
            errorMsg = errorMsg + 'Please enter a valid number < 50 ';
            winerack.nrOfBottleGrids.style.backgroundColor = "#F8D7DA";
            winerack.nrOfBottleGrids.value = '';
            winerack.nrOfBottleGrids.placeholder = 'Amount of shelves - Enter a valid number < 50 ';
        } else {
            winerack.nrOfBottleGrids.style.backgroundColor = "#ffffff";
            winerack.nrOfBottleGrids.placeholder = 'Amount of shelves';
        }

        if (errorMsg !== '') {
            throw errorMsg;
        }
    }

    handleRestCallAddWineRack(body, target) {
        httpPostWithSecurityToken(`/api/add-winerack-for-user`, body, this.props.token)
            .then(response => {
                if (response.status === 200) {
                    let slotsArrayDto = response.data.bottleSlots;
                    slotsArrayDto.sort(compareSlots);

                    //Makes 2 dimentional slot array of 1 D slot array (of newly added winerack) and put it in a wineRack, add that to list of wineracks
                    let previousYposition = null;
                    let slotRow = [];
                    let slotsArray2D = [];
                    let rowIndex = 0;
                    for (let slotIndex = 0; slotIndex < slotsArrayDto.length; slotIndex++) {
                        //New row of slots
                        if (previousYposition !== null && previousYposition !== slotsArrayDto[slotIndex].y_position) {
                            slotsArray2D.push(slotRow);
                            slotRow = [];
                            rowIndex++;

                            //Add a bottle grid row when the rack has bottle grids and when there have been response.data.verticalSize amount of rows
                            if (response.data.nrOfBottleGrids != null && (rowIndex % (response.data.verticalSize) === 0)) {
                                let bottleGridRow = [];
                                for (let bottleGridRowIndex = 0; bottleGridRowIndex < response.data.horizontalSize; bottleGridRowIndex++) {
                                    bottleGridRow.push(BOTTLE_GRID_PLACEHOLDER);
                                }
                                slotsArray2D.push(bottleGridRow);
                            }
                        }
                        slotRow.push(slotsArrayDto[slotIndex]);
                        previousYposition = slotsArrayDto[slotIndex].y_position;

                    }
                    slotsArray2D.push(slotRow);

                    let updatedWineRackData = this.state.wineRackData.slice();
                    let ids = [];

                    for (let i = 0; i < slotsArray2D.length; i++) {
                        for (let j = 0; j < slotsArray2D[i].length; j++) {
                            ids.push(response.data.id + '-' + slotsArray2D[i][j].x_position + '-' + slotsArray2D[i][j].y_position);
                            console.log('slot db id=' + response.data.id + '-' + slotsArray2D[i][j].x_position + '-' + slotsArray2D[i][j].y_position);
                        }
                    }

                    let wineRack = {
                        data: response.data,
                        slotArray2D: slotsArray2D,
                        wineRackAndSlotIds: ids
                    };

                    updatedWineRackData.push(wineRack);

                    this.clearInputFieldsWineRack(target);
                    this.setState({
                        wineRackAdded: true,
                        wineRackData: updatedWineRackData
                    })
                } else {
                    this.setState({
                        wineRackAdded: false,
                        addWineRackInputFieldsErrorMsg: 'Something went wrong while adding wine rack'
                    })
                }
            })
            .catch(error => {
                if (error.response.status === 412) {
                    this.setState({
                        wineRackAdded: false,
                        addWineRackInputFieldsErrorMsg: error.response.data
                    })
                    target.name.style.backgroundColor = "#F8D7DA";
                } else {
                    this.setState({
                        wineRackAdded: false,
                        addWineRackInputFieldsErrorMsg: 'Something went wrong while adding wine rack'
                    })
                }
            });
    }

    bottleSlotPopup(event) {
        let timeOut = setTimeout(() => {
            document.getElementById("bottleSlotMenuId").style.display = "block";
        }, 1000);
        this.setState({
            bottleSlotMenuPopupTimeOut: timeOut,
        })
    }

    //User clicks close when in bottle slot menu
    userClicksClose() {
        document.getElementById("bottleSlotMenuId").style.display = "none";
    }

    //User clicks insert when in bottle slot menu to insert bottle in slot
    userClicksInsert(selectedOption) {
        let bottleId = selectedOption.value.id;
        let wineRackId = this.state.selectedWineRackIdBottleSlotMenu;
        let xPosition = this.state.selectedSlotXPosBottleSlotMenu;
        let yPosition = this.state.selectedSlotYPosBottleSlotMenu;

        let oldBottle = this.getBottleFromWineRackData(this.state.wineRackData, wineRackId, xPosition, yPosition);

        httpPostNoBodyWithSecurityToken(`/api/add-bottle-to-winerack?bottleId=${bottleId}&wineRackId=${wineRackId}&xPosition=${xPosition}&yPosition=${yPosition}`, this.props.token)
            .then(response => {
                if (response.status === 200) {
                    let bottleSuggestionsToAdapt = this.state.bottleSuggestions.slice();

                    //Update the stock bottles not yet in winerack list by removing the bottle put in a slot
                    for (let i = 0, l1 = this.state.bottleSuggestions.length; i < l1; i++) {
                        let bottle = this.state.bottleSuggestions[i].value;

                        if (bottle.id === parseInt(bottleId)) {
                            bottleSuggestionsToAdapt.splice(i, 1);
                            break;
                        }
                    }
                    //If slot already contained a bottle, this bottle has to be put back in the bottleSuggestions list again
                    if (oldBottle != null) {
                        let display = nullToEmpty(oldBottle.description) + ' ' + nullToEmpty(oldBottle.winery) + ' ' + nullToEmpty(oldBottle.country) + ' ' + nullToEmpty(oldBottle.vintage) + ' ' + nullToEmpty(oldBottle.price);
                        let oldBottleFormatted = {value: oldBottle, label: display}; //format to make the Select (bottles) component to work
                        bottleSuggestionsToAdapt.unshift(oldBottleFormatted); //TODO maybe sort the list too
                    }

                    //Update the wineRackData with a slot that now contains a bottle
                    let wineRackDataCopy = this.state.wineRackData.slice();
                    for (let i = 0, l1 = this.state.wineRackData.length; i < l1; i++) {
                        let wineRack = this.state.wineRackData[i];

                        for (let j = 0; j < wineRack.slotArray2D.length; j++) {
                            let slotRow = wineRack.slotArray2D[j];

                            for (let k = 0; k < slotRow.length; k++) {
                                if (slotRow[k].id === response.data.id) {
                                    wineRackDataCopy[i].slotArray2D[j][k].bottle = response.data.bottle;
                                    console.log(wineRackDataCopy[i].slotArray2D[j][k].bottle);
                                }
                            }
                        }
                    }
                    document.getElementById("bottleSlotMenuId").style.display = "none";

                    this.setState({
                        selectedWineRackIdBottleSlotMenu: null,
                        selectedSlotXPosBottleSlotMenu: null,
                        selectedSlotYPosBottleSlotMenu: null,
                        bottleSuggestions: bottleSuggestionsToAdapt,
                        wineRackData: wineRackDataCopy,
                    })
                }

            })
            .catch(error => {

            });
    }

    drinkBottleFromWineRack(event) {
        event.preventDefault();

        let xPos = this.state.selectedSlotXPosBottleSlotMenu;
        let yPos = this.state.selectedSlotYPosBottleSlotMenu;

        let wineRackDataToUpdate = this.state.wineRackData.slice();

        httpPostNoBodyWithSecurityToken(`/api/drink-bottle-from-winerack?wineRackId=${this.state.selectedWineRackIdBottleSlotMenu}
            &xPosition=${this.state.selectedSlotXPosBottleSlotMenu}&yPosition=${this.state.selectedSlotYPosBottleSlotMenu}&tastingNotes=${event.target.tastingNotesId.value}&scoreOnTen=${event.target.scoreOnTenId.value}`, this.props.token)
            .then(response => {
                if (response.status === 200) {
                    this.removeBottleFromWineRackPosition(wineRackDataToUpdate, this.state.selectedWineRackIdBottleSlotMenu, xPos, yPos);

                    document.getElementById("scoreAndTastingNotesPopupId").style.display = "none";

                    this.setState({
                        selectedWineRackIdBottleSlotMenu: null,
                        selectedSlotXPosBottleSlotMenu: null,
                        selectedSlotYPosBottleSlotMenu: null,
                        wineRackData: wineRackDataToUpdate,
                    })
                }
            })
            .catch(error => {

            });
    }

    removeBottleFromWineRack() {
        let xPos = this.state.selectedSlotXPosBottleSlotMenu;
        let yPos = this.state.selectedSlotYPosBottleSlotMenu;

        let wineRackDataToUpdate = this.state.wineRackData.slice();
        httpPostNoBodyWithSecurityToken(`/api/remove-bottle-from-winerack?wineRackId=${this.state.selectedWineRackIdBottleSlotMenu}&xPosition=${this.state.selectedSlotXPosBottleSlotMenu}&yPosition=${this.state.selectedSlotYPosBottleSlotMenu}`, this.props.token)
            .then(response => {
                if (response.status === 200) {
                    let removedBottle = this.removeBottleFromWineRackPosition(wineRackDataToUpdate, this.state.selectedWineRackIdBottleSlotMenu, xPos, yPos);

                    //Put removed bottle back into the bottle suggestions list
                    let bottleSuggestionsToAdapt = this.addBottleToBottleSuggestionsList(removedBottle);

                    document.getElementById("bottleSlotMenuId").style.display = "none";

                    this.setState({
                        selectedWineRackIdBottleSlotMenu: null,
                        selectedSlotXPosBottleSlotMenu: null,
                        selectedSlotYPosBottleSlotMenu: null,
                        wineRackData: wineRackDataToUpdate,
                        bottleSuggestions: bottleSuggestionsToAdapt
                    })
                }

            })
            .catch(error => {

            });
    }

    removeBottleFromWineRackPosition(wineRackDataToUpdate, selectedWineRackId, xPos, yPos) {
        let removedBottle = null;
        for (let i = 0; i < wineRackDataToUpdate.length; i++) {
            let wineRack = wineRackDataToUpdate[i];

            if (wineRack.data.id === parseInt(selectedWineRackId)) {
                for (let j = 0; j < wineRack.slotArray2D.length; j++) {
                    let slotRow = wineRack.slotArray2D[j];

                    for (let k = 0; k < slotRow.length; k++) {
                        if (slotRow[k].x_position === parseInt(xPos) && slotRow[k].y_position === parseInt(yPos)) {
                            removedBottle = slotRow[k].bottle;
                            slotRow[k].bottle = null;
                            break;
                        }
                    }
                }
            }
        }
        return removedBottle;
    }


    addBottleToBottleSuggestionsList(bottle) {
        let bottleSuggestionsToAdapt = this.state.bottleSuggestions.slice();
        if (bottle != null) {
            let display = nullToEmpty(bottle.description) + ' ' + nullToEmpty(bottle.country) + ' ' + nullToEmpty(bottle.vintage) + ' ' + nullToEmpty(bottle.price);
            let bottleFormatted = {value: bottle, label: display}; //format to make the Select (bottles) component to work
            bottleSuggestionsToAdapt.unshift(bottleFormatted);
        }
        return bottleSuggestionsToAdapt;
    }

    //User clicks cancel when deleting a bottle
    userClicksCancelDeleteWineRack() {
        document.getElementById("deleteWineRackPopup").style.display = "none";
    }

    //User confirms to delete a bottle
    userClicksOkDeleteWineRack() {
        document.getElementById("deleteWineRackPopup").style.display = "none";
        this.deleteWineRack(this.state.wineRackToDeleteId);
    }

    confirmDeletion(event) {
        document.getElementById("deleteWineRackPopup").style.display = "block";
        this.setState({
            wineRackToDeleteId: event.target.id,
            wineRackToDeleteName: event.target.title
        });
    }

    deleteWineRack(wineRackId) {
        httpPostNoBodyWithSecurityToken(`/api/delete-winerack?wineRackId=${wineRackId}`, this.props.token)
            .then(response => {
                if (response.status === 200) {
                    //remove winerack with that id from the javascript data and set state
                    let indexToRemove = null;
                    let wineRackDataToUpdate = this.state.wineRackData.slice();
                    for (let i = 0; i < this.state.wineRackData.length; i++) {
                        console.log(this.state.wineRackData[i].data.id + ' ' + wineRackId);
                        if (this.state.wineRackData[i].data.id === parseInt(wineRackId)) {
                            indexToRemove = i;
                        }
                    }

                    if (indexToRemove != null) {
                        wineRackDataToUpdate.splice(indexToRemove, 1);
                    }
                    this.loadStockBottlesNotInWineRack();

                    this.setState({
                        wineRackData: wineRackDataToUpdate
                    })
                }
            })
            .catch(error => {

            });
    }

    displayWineRackEditPopup(event) {
        document.getElementById("editWineRackPopup").style.display = "block";

        this.setState({
            wineRackToEditId: event.target.id,
            wineRackToEdit: this.getWineRackById(event.target.id)
        });
    }


    //User clicks cancel when editing a wine rack
    userClicksCancelEditWineRack() {
        document.getElementById("editWineRackPopup").style.display = "none";
        this.resetElement(document.getElementById("newNameWineRackInput"), 'New wine rack name');
    }

    //User confirms to change wine rack name
    userClicksChangeNameWineRack(event) {
        event.preventDefault();
        document.getElementById("editWineRackPopup").style.display = "none";

        let newWineRackName = document.getElementById("newNameWineRackInput").value;
        document.getElementById("newNameWineRackInput").value = '';

        httpPostNoBodyWithSecurityToken(`/api/change-winerack-name?wineRackId=${this.state.wineRackToEditId}&wineRackName=${newWineRackName}`, this.props.token)
            .then(response => {
                if (response.status === 200) {
                    let wineRackDataToUpdate = this.state.wineRackData.slice();

                    for (let wineRackIndex = 0; wineRackIndex < this.state.wineRackData.length; wineRackIndex++) {
                        let wineRack = this.state.wineRackData[wineRackIndex];

                        console.log(this.state.wineRackToEditId + '-' + wineRack.data.id);
                        if (this.state.wineRackToEditId.toString() === wineRack.data.id.toString()) {
                            wineRackDataToUpdate[wineRackIndex] = this.buildWineRack(response.data);
                        }
                    }
                    this.setState({
                        wineRackToEditId: null,
                        wineRackToEdit: null,
                        wineRackData: wineRackDataToUpdate
                    })
                }
            })
            .catch(error => {
                console.log('error ' + error);
                document.getElementById("editWineRackPopup").style.display = "block";

                let wineRackNameInputField = document.getElementById("newNameWineRackInput")
                wineRackNameInputField.style.backgroundColor = "#F8D7DA";
                wineRackNameInputField.placeholder = "Wine rack name has to be unique and not empty.";
                wineRackNameInputField.value = '';
            });
    }

    //User clicks remove all bottles button
    //TODO make an are you sure popup
    userClicksRemoveAllBottlesWineRack(event) {
        event.preventDefault();
        document.getElementById("editWineRackPopup").style.display = "none";

        httpPostNoBodyWithSecurityToken(`/api/remove-all-bottles-from-winerack?wineRackId=${this.state.wineRackToEditId}`, this.props.token)
            .then(response => {
                if (response.status === 200) {
                    let wineRackDataToUpdate = this.state.wineRackData.slice();

                    for (let wineRackIndex = 0; wineRackIndex < this.state.wineRackData.length; wineRackIndex++) {
                        let wineRack = this.state.wineRackData[wineRackIndex];

                        console.log(this.state.wineRackToEditId + '-' + wineRack.data.id);
                        if (this.state.wineRackToEditId.toString() === wineRack.data.id.toString()) {
                            wineRackDataToUpdate[wineRackIndex] = this.buildWineRack(response.data);
                        }
                    }
                    this.loadStockBottlesNotInWineRack();

                    this.setState({
                        wineRackToEditId: null,
                        wineRackToEdit: null,
                        wineRackData: wineRackDataToUpdate
                    })
                }
            })
            .catch(error => {
            });
    }

    userClicksAddHorizontalRowOnTop(event) {
        event.preventDefault();
        document.getElementById("editWineRackPopup").style.display = "none";

        httpPostNoBodyWithSecurityToken(`/api/add-row-top?wineRackId=${this.state.wineRackToEditId}`, this.props.token)
            .then(response => {
                if (response.status === 200) {
                    this.updateWineRackData(response);
                }
            })
            .catch(error => {
                console.log('error ' + error);
                document.getElementById("editWineRackPopup").style.display = "block";
            });
    }

    userClicksAddHorizontalRowAtBottom(event) {
        event.preventDefault();
        document.getElementById("editWineRackPopup").style.display = "none";

        httpPostNoBodyWithSecurityToken(`/api/add-row-bottom?wineRackId=${this.state.wineRackToEditId}`, this.props.token)
            .then(response => {
                if (response.status === 200) {
                    this.updateWineRackData(response);
                }
            })
            .catch(error => {
                console.log('error ' + error);
                document.getElementById("editWineRackPopup").style.display = "block";
            });
    }

    userClicksAddVerticalColumnAtLeft(event) {
        event.preventDefault();
        document.getElementById("editWineRackPopup").style.display = "none";

        httpPostNoBodyWithSecurityToken(`/api/add-column-left?wineRackId=${this.state.wineRackToEditId}`, this.props.token)
            .then(response => {
                if (response.status === 200) {
                    this.updateWineRackData(response);
                }
            })
            .catch(error => {
                console.log('error ' + error);
                document.getElementById("editWineRackPopup").style.display = "block";
            });
    }

    userClicksAddVerticalColumnAtRight(event) {
        event.preventDefault();
        document.getElementById("editWineRackPopup").style.display = "none";

        httpPostNoBodyWithSecurityToken(`/api/add-column-right?wineRackId=${this.state.wineRackToEditId}`, this.props.token)
            .then(response => {
                if (response.status === 200) {
                    this.updateWineRackData(response);
                }
            })
            .catch(error => {
                console.log('error ' + error);
                document.getElementById("editWineRackPopup").style.display = "block";
            });
    }

    userAddsOrRemovesRowsOrColumns(action) {
        console.log('Action = ' + action);
        document.getElementById("editWineRackPopup").style.display = "none";

        httpPostNoBodyWithSecurityToken(`/api/` + action + `?wineRackId=${this.state.wineRackToEditId}`, this.props.token)
            .then(response => {
                if (response.status === 200) {
                    this.updateWineRackData(response);
                }
            })
            .catch(error => {
                console.log('error ' + error);
                document.getElementById("editWineRackPopup").style.display = "block";
            });
    }

    updateWineRackData(updatedWineRack) {
        let wineRackDataToUpdate = this.state.wineRackData.slice();
        for (let wineRackIndex = 0; wineRackIndex < this.state.wineRackData.length; wineRackIndex++) {
            let wineRack = this.state.wineRackData[wineRackIndex];

            console.log(this.state.wineRackToEditId + '-' + wineRack.data.id);
            if (this.state.wineRackToEditId.toString() === wineRack.data.id.toString()) {
                wineRackDataToUpdate[wineRackIndex] = this.buildWineRack(updatedWineRack.data);
            }
        }
        this.setState({
            wineRackToEditId: null,
            wineRackToEdit: null,
            wineRackData: wineRackDataToUpdate
        })
    }

    resetElement(elementId, placeHolder) {
        let wineRackNameInputField = document.getElementById(elementId)
        wineRackNameInputField.style.backgroundColor = "#FFFFFF";
        wineRackNameInputField.placeholder = placeHolder;
        wineRackNameInputField.value = '';
    }

    buildWineRack(rawWineRackData) {
        let wineRack = {
            data: rawWineRackData,
            slotArray2D: [],
            wineRackAndSlotIds: []
        };

        let slotsArray = rawWineRackData.bottleSlots;
        slotsArray.sort(compareSlots);

        let slotArray2D = [];
        let slotRow = [];
        let bottleGridRow = [];
        let previousYposition = null;
        let rowIndex = 1;

        for (let slotIndex = 0; slotIndex < slotsArray.length; slotIndex++) {
            if (previousYposition !== null && previousYposition !== slotsArray[slotIndex].y_position) {
                slotArray2D.push(slotRow);

                bottleGridRow = [];
                //if there are bottlegrids, build an extra bottle grid row when appropriate according to y dimension
                if (rawWineRackData.nrOfBottleGrids != null && (rowIndex % (rawWineRackData.verticalSize) === 0)) {
                    for (let bottleGridRowIndex = 0; bottleGridRowIndex < slotRow.length; bottleGridRowIndex++) {
                        bottleGridRow.push(BOTTLE_GRID_PLACEHOLDER);
                    }
                    slotArray2D.push(bottleGridRow);
                }
                rowIndex++;
                slotRow = [];
            }
            slotRow.push(slotsArray[slotIndex]);
            previousYposition = slotsArray[slotIndex].y_position;
        }
        slotArray2D.push(slotRow);

        if (rawWineRackData.nrOfBottleGrids != null) {
            slotArray2D.push(bottleGridRow);
        }
        rowIndex = 0;

        wineRack.slotArray2D = slotArray2D;
        let ids = [];
        for (let i = 0; i < wineRack.slotArray2D.length; i++) {
            for (let j = 0; j < wineRack.slotArray2D[i].length; j++) {
                ids.push(wineRack.data.id + '-' + wineRack.slotArray2D[i][j].x_position + '-' + wineRack.slotArray2D[i][j].y_position);
            }
        }
        wineRack.wineRackAndSlotIds = ids;
        return wineRack;
    }

    processMouseClick(event) {
        document.getElementById("bottleSlotMenuId").style.display = "block";
        let wineRackAndSlotId = event.target.id;
        let ids = wineRackAndSlotId.split('-');

        let wineRackId = ids[0];
        let slotXPos = ids[1];
        let slotYPos = ids[2];

        let bottle = this.getBottleFromWineRackData(this.state.wineRackData, wineRackId, slotXPos, slotYPos);

        let bottleDescription = bottle != null ? bottle.description + ' ' + nullToEmpty(bottle.country) + '  ' + nullToEmpty(bottle.vintage) : '';

        this.setState({
            selectedWineRackIdBottleSlotMenu: wineRackId,
            selectedSlotXPosBottleSlotMenu: slotXPos,
            selectedSlotYPosBottleSlotMenu: slotYPos,
            clickedBottleDescription: bottleDescription
        })
    }

    getBottleFromWineRackData(wineRackData, wineRackId, slotXPos, slotYPos) {
        for (let i = 0; i < wineRackData.length; i++) {
            let wineRack = this.state.wineRackData[i];

            if (wineRack.data.id === parseInt(wineRackId)) {
                for (let j = 0; j < wineRack.slotArray2D.length; j++) {
                    let slotRow = wineRack.slotArray2D[j];

                    for (let k = 0; k < slotRow.length; k++) {
                        if (slotRow[k].x_position === parseInt(slotXPos) && slotRow[k].y_position === parseInt(slotYPos)) {
                            return slotRow[k].bottle;
                        }
                    }
                }
            }
        }
        return null;
    }

    getWineRackById(wineRackId) {
        for (let i = 0; i < this.state.wineRackData.length; i++) {
            let wineRack = this.state.wineRackData[i];

            if (wineRack.data.id === parseInt(wineRackId)) {
                return wineRack.data;
            }
        }
    }

    drinkBottlePopup() {
        document.getElementById("bottleSlotMenuId").style.display = "none";
        document.getElementById("scoreAndTastingNotesPopupId").style.display = "block";

    }

    userClicksCancelDrink(event) {
        document.getElementById("scoreAndTastingNotesPopupId").style.display = "none";
    }

    userClicksAddWineRack() {
        document.getElementById("addWineRackFormId").style.display = "block";
    }

    userClicksCancelAddWineRack() {
        document.getElementById("addWineRackFormId").style.display = "none";
    }

    userClicksNavigateToRackPopUp() {
        document.getElementById("navigateToRackPopUpId").style.display = "block";
    }


    render() {
        return (
            <div className="shadow pt-3 pb-1 pl-3 pr-3 mb-3 bg-white rounded">

                <BottleSlotMenuPopUp drinkBottlePopup={this.drinkBottlePopup}
                                     removeBottleFromWineRack={this.removeBottleFromWineRack}
                                     userClicksInsert={this.userClicksInsert} userClicksClose={this.userClicksClose}
                                     clickedBottleDescription={this.state.clickedBottleDescription}
                                     bottleSuggestions={this.state.bottleSuggestions}
                                     bottleSelectedOption={this.state.bottleSelectedOption}/>

                <ScoreAndTastingNotesPopUp drinkBottleFromWineRack={this.drinkBottleFromWineRack}
                                           userClicksCancelDrink={this.userClicksCancelDrink}
                                           clickedBottleDescription={this.state.clickedBottleDescription}/>

                <DeleteWineRackPopUp userClicksCancelDeleteWineRack={this.userClicksCancelDeleteWineRack}
                                     userClicksOkDeleteWineRack={this.userClicksOkDeleteWineRack}
                                     wineRackToDeleteName={this.state.wineRackToDeleteName}/>

                <EditWineRackPopUp userClicksCancelEditWineRack={this.userClicksCancelEditWineRack}
                                   userClicksChangeNameWineRack={this.userClicksChangeNameWineRack}
                                   userClicksAddHorizontalRowOnTop={this.userClicksAddHorizontalRowOnTop}
                                   userClicksAddHorizontalRowAtBottom={this.userClicksAddHorizontalRowAtBottom}
                                   userClicksAddVerticalColumnAtLeft={this.userClicksAddVerticalColumnAtLeft}
                                   userClicksAddVerticalColumnAtRight={this.userClicksAddVerticalColumnAtRight}
                                   userClicksRemoveAllBottlesWineRack={this.userClicksRemoveAllBottlesWineRack}
                                   userAddsOrRemovesRowsOrColumns={this.userAddsOrRemovesRowsOrColumns}
                                   wineRackToEdit={this.state.wineRackToEdit}/>

                <AddWineRackPopUp addWineRack={this.addWineRack} onChangeBottleGrid={this.onChangeBottleGrid}
                                  userClicksCancelAddWineRack={this.userClicksCancelAddWineRack}
                                  gridSuggestions={this.state.gridSuggestions}
                                  gridSelectedOption={this.state.gridSelectedOption}/>

                <NavigateToRackPopUp wineRackData={this.state.wineRackData}/>

                <div align="center">
                    {// Wine rack added popup
                        this.state.wineRackAdded &&

                        <div className="overlay-fade-in-out" style={{display: "block"}}>
                            <div className="fade-in-out">
                                <div className="popup">
                                    <p><h5>Wine rack added </h5></p>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div align="center">
                    <p className="height-fifty-pixels">&nbsp;</p>
                </div>

                <div style={{position: "fixed"}}>
                    <Table id="wineRacksMenuWidgetId">
                        <tbody>
                        <tr>
                            <td>
                                <a id="addWineRackLinkId" href="#/" className="button-link"
                                   onClick={this.userClicksAddWineRack}>Create Rack...</a>
                            </td>
                            <td>
                                <a id="navigateToRackId" href="#/" style={{backgroundColor: "gray"}}
                                   className="button-link" onClick={this.userClicksNavigateToRackPopUp}>Jump to
                                    rack...</a>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>

                <p className="height-fifty-pixels">&nbsp;</p>

                {
                    this.state.wineRackData.map((wineRack, wineRackIdx) => {
                            let bottleGridIndex = 0;
                            return (
                                <div>
                                    <div id={wineRack.data.id + "-navigate"} className="anchor"/>
                                    {
                                        wineRackIdx > 0 && <p className="height-fifty-pixels">&nbsp;</p> //no fifty pixels space needed for first rack
                                    }
                                    <table>
                                        <tr>
                                            <td><h5>{wineRack.data.name}</h5></td>
                                            <td>&nbsp;&nbsp;&nbsp;</td>
                                            <td><a href="#/" style={{color: '#630d35'}} onClick={this.confirmDeletion}>
                                                <i id={wineRack.data.id} title={wineRack.data.name}
                                                   className="fa fa-trash"/></a></td>
                                            <td>&nbsp;&nbsp;&nbsp;</td>
                                            <td><a href="#/" style={{color: '#630d35'}}
                                                   onClick={this.displayWineRackEditPopup}>
                                                <i id={wineRack.data.id} title={wineRack.data.name}
                                                   className="fa fa-edit"/></a></td>
                                        </tr>
                                    </table>
                                    <Table id={wineRack.data.id} responsive="sm">
                                        <tbody>
                                        {
                                            wineRack.slotArray2D.map((row, rowIdx) => {
                                                if (row[0] === BOTTLE_GRID_PLACEHOLDER) {
                                                    return (
                                                        <tr>
                                                            {
                                                                row.map((cell, cellIdx) => {
                                                                    //used to display an extra (bottle grid) row but only the first cell should be numbered
                                                                    if (cellIdx === 0) {
                                                                        bottleGridIndex++;
                                                                        return (
                                                                            <td style={{
                                                                                paddingTop: 1,
                                                                                paddingBottom: 1,
                                                                                backgroundColor: '#630d35',
                                                                                border: "none"
                                                                            }}><p style={{
                                                                                fontWeight: 'bold',
                                                                                color: 'white',
                                                                                height: '5px'
                                                                            }}>{bottleGridIndex} </p></td>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <td style={{
                                                                                paddingTop: 1,
                                                                                paddingBottom: 1,
                                                                                backgroundColor: '#630d35',
                                                                                border: "none"
                                                                            }}/>
                                                                        );
                                                                    }
                                                                })
                                                            }
                                                        </tr>
                                                    );
                                                } else {
                                                    return (
                                                        <tr>
                                                            {wineRack.data.nrOfBottleGrids === null && //add row numbering for normal wine rack rows
                                                                <td><p style={{
                                                                    fontWeight: 'bold',
                                                                    color: '#630d35',
                                                                    height: '5px'
                                                                }}>{rowIdx + 1} </p></td>
                                                            }
                                                            {
                                                                row.map((cell, cellIdx) => {
                                                                    let cellDisplay = cell.bottle == null ? OPEN_SLOT_PLACEHOLDER : nullToEmpty(cell.bottle.description) + ' ' + nullToEmpty(cell.bottle.winery) + ' ' + nullToEmpty(cell.bottle.country) + ' ' + nullToEmpty(cell.bottle.vintage) + ' ' + nullToEmpty(cell.bottle.price);
                                                                    //cellDisplay =  rowIdx % wineRack.data.nrOfBottleGrids === 0 ? '</hr>' : cellDisplay;
                                                                    let backgroundColour = cell.bottle == null ? "#ffffff" : "#FDEEF5";
                                                                    //use cell.x_position and cell.y_position to display for debugging purposes if something is wrong
                                                                    return (
                                                                        <td id={wineRack.data.id + '-' + cell.x_position + '-' + cell.y_position}
                                                                            onTouchMove={this.touchMove}
                                                                            onTouchStart={this.touchStart}
                                                                            onTouchEnd={this.touchEnd}
                                                                            onDragEnter={this.dragEnter}
                                                                            onDragOver={this.dragOver}
                                                                            onDragStart={this.dragStart}
                                                                            onDrop={this.drop}
                                                                            onMouseDown={this.processMouseClick}
                                                                            draggable="true"
                                                                            style={{backgroundColor: backgroundColour}}>{cellDisplay}</td>
                                                                    );
                                                                })
                                                            }
                                                        </tr>
                                                    );
                                                }
                                            })
                                        }
                                        </tbody>
                                    </Table>
                                </div>
                            );
                        }
                    )
                }
            </div>
        );
    }
}

export default WineRacksScreen;