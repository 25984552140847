import * as React from "react";
import Select from "react-select";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {FormLabel} from "react-bootstrap";
import {Input} from "@material-ui/core";

class EditWineRackPopUp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            action: "add",
            target: "row",
            positionOptions: [],
            position: "top",
        };
    }

    handleTargetChange = (selectedOption) => {
        const target = selectedOption?.value || null;
        const positionOptions =
            target === "row"
                ? [
                    {value: "top", label: "Top"},
                    {value: "bottom", label: "Bottom"},
                ]
                : [
                    {value: "left", label: "Left"},
                    {value: "right", label: "Right"},
                ];
        this.setState({
            target: selectedOption,
            positionOptions,
            position: null,
        });
    };

    handleActionChange = (selectedOption) => {
        this.setState({action: selectedOption});
    };

    handlePositionChange = (selectedOption) => {
        this.setState({position: selectedOption});
    };

    render() {
        const {action, target, positionOptions, position} = this.state;

        const actionOptions = [
            {value: "add", label: "Add"},
            {value: "remove", label: "Remove"},
        ];

        const targetOptions = [
            {value: "row", label: "Row"},
            {value: "column", label: "Column"},
        ];

        return (
            <div className="overlay" id="editWineRackPopup">
                {this.props.wineRackToEdit !== null &&
                    <div className="popup">
                        <p><h5>Edit wine rack {this.props.wineRackToEdit.name} </h5></p>
                        <p className="height-five-pixels"/>
                        {this.props.wineRackToEdit.nrOfBottleGrids === null &&
                            <div>
                                <Row>
                                    <Col>
                                        <FormLabel>
                                            <h6>Add/Remove rows/columns from wine rack</h6>
                                        </FormLabel>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <form>
                                            <Select
                                                isMulti={false}
                                                className="basic-multi-select"
                                                options={actionOptions}
                                                onChange={this.handleActionChange}
                                                value={action}
                                                defaultValue={actionOptions[0]}
                                                classNamePrefix="select"/>
                                        </form>
                                    </Col>
                                    <Col>
                                        <form>
                                            <Select
                                                isMulti={false}
                                                className="basic-multi-select"
                                                options={targetOptions}
                                                onChange={this.handleTargetChange}
                                                value={target}
                                                defaultValue={actionOptions[0]}
                                                classNamePrefix="select"/>
                                        </form>
                                    </Col>
                                    <Col>
                                        <form>
                                            <Select
                                                isMulti={false}
                                                className="basic-multi-select"
                                                options={positionOptions}
                                                onChange={this.handlePositionChange}
                                                value={position}
                                                defaultValue={{value: "top", label: "Top"}}
                                                classNamePrefix="select"
                                                isDisabled={!target}/>
                                        </form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p className="height-five-pixels">&nbsp;</p>
                                        <a href={"#" + this.props.wineRackToEdit.id + "-navigate"}
                                           id="userAddsOrRemovesRowsOrColumnsButton" className="button-link"
                                           onClick={() => this.props.userAddsOrRemovesRowsOrColumns(action.value + '-' + target.value + '-' + position.value)}>
                                            Submit
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        }

                        <p className="height-three-pixels">&nbsp;</p>
                        <hr/>
                        <p className="height-three-pixels">&nbsp;</p>

                        <div>
                            <Row>
                                <Col>
                                    <FormLabel>
                                        <h6>Change wine rack name</h6>
                                    </FormLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Input id="newNameWineRackInput" placeholder="New wine rack name" size="25"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p className="height-five-pixels">&nbsp;</p>
                                    <a href="#" id="changeWineRackNameButton"
                                       onClick={this.props.userClicksChangeNameWineRack}
                                       className="button-link">
                                        Submit
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p className="height-three-pixels">&nbsp;</p>
                                    <hr/>
                                    <p className="height-three-pixels">&nbsp;</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormLabel>
                                        <h6>Remove bottles</h6>
                                    </FormLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p className="height-five-pixels">&nbsp;</p>
                                    <a href="#" id="userClicksRemoveAllBottlesWineRack"
                                       onClick={this.props.userClicksRemoveAllBottlesWineRack}
                                       className="button-link">Remove all bottles</a>
                                </Col>
                            </Row>
                        </div>
                        <div className="text-right">
                            <button className="btn btn-cancel" onClick={this.props.userClicksCancelEditWineRack}>Cancel
                            </button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default EditWineRackPopUp;